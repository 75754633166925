body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.calenderJobCompleted {
  background-color: #2a882a !important;
  color: white !important;
}
.calenderJobNotCompleted {
  background-color: #d32f2f !important;
  color: white !important;
}
.calenderJobNoVehicle {
  background-color: #0181e3 !important;
  color: white !important;
}
.calenderJobOnHold {
  background-color: #ffb100 !important;
  color: white !important;
}
.calenderJobNotAttempted {
  background-color: #6300b3 !important;
  color: white !important;
}

.calenderWeekOff {
  background-color: #6d6d6d !important;
  color: white !important;
}
.custom-width-text-align{
  width: 50rem;
  text-align:center
}
